<template>
  <!-- Main content -->
  <section class="content">
    <el-table
        v-loading="loading"
        :data="activeityList"
        border>
      <el-table-column label="活动名称" prop="act_name"></el-table-column>
      <el-table-column label="活动版本" prop="act_version"></el-table-column>
      <el-table-column label="活动标识" prop="act_key"></el-table-column>
      <el-table-column label="活动开始时间">
        <template slot-scope="scope">
          {{scope.row.start_time | dateTime}}
        </template>
</el-table-column>
<el-table-column label="活动结束时间">
    <template slot-scope="scope">
          {{scope.row.end_time | dateTime}}
        </template>
</el-table-column>
<el-table-column label="状态">
    <template slot-scope="scope">
          {{scope.row.status | status}}
        </template>
</el-table-column>
<el-table-column label="操作">
    <template slot-scope="scope">
        <el-button type="primary" size="mini" @click="manage(scope.row.id,scope.row.act_version,scope.row.status,scope.row.start_time,scope.row.end_time)">修改</el-button>
    </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>

<el-dialog title="修改" :visible.sync="dialog" :close-on-click-modal=false>
    <el-form>
        <el-form-item label="版本号">
            <el-input v-model="act_version" type="number" placeholder="请输入版本号"></el-input>
        </el-form-item>
        <el-form-item label="状态">
            <el-radio-group v-model="status">
                <el-radio :label="1">进行中</el-radio>
                <el-radio :label="2">已关闭</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="时间">
            <el-date-picker
                v-model="date_value"
                type="datetimerange"
                format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                value-format="timestamp"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter">确 定</el-button>
    </div>
</el-dialog>
</section>
</template>
<script>
    import * as api from '@/config/api'
    export default {
        name: 'activeityList',
        data() {
            return {
                dialog: false,
                loading: true,
                activeityList: [],
                total: 0,
                pagesize: 10,
                page: 1,

                id: '',
                act_version: '',
                status: '',
                date_value:'',
            }
        },
        filters: {
            status(val) {
                switch (Number(val)) {
                    case 1:
                        return '进行中'
                        break;
                    case 2:
                        return '已关闭'
                        break;
                }
            },
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            }
        },
        mounted() {
            this.getActivityList()
        },
        methods: {
            getActivityList(page, size) {
                api.getActivityList({
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize
                }, res => {
                    this.activeityList = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getActivityList()
            },
            currentChange(val) {
                this.page = val
                this.getActivityList()
            },
            manage(id, version, status,s_date,e_date) {
                this.id = id
                this.act_version = version
                this.status = Number(status)
                this.dialog = true
                console.log(s_date,e_date)
                s_date = s_date ? s_date * 1000 : '';
                e_date = e_date ? e_date * 1000 : '';
                this.date_value = s_date && e_date && [s_date,e_date] || '';
            },
            alter() {
                let start_time ='';
                let end_time = '';
                if(this.date_value && this.date_value.length>0){
                    start_time = this.date_value[0] / 1000;
                    end_time = this.date_value[1] / 1000;
                }

                console.log( this.date_value);
                api.postActivity({
                    id: this.id,
                    act_version: this.act_version,
                    status: this.status,
                    start_time,
                    end_time
                }, res => {
                    this.dialog = false
                    this.getActivityList()
                })
            }
        }
    }
</script>